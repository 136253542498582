
// ==========================================================================
// Footer SCSS
// ==========================================================================

/* =========================================================
Footer
========================================================= */

.footer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  background-color: #eee;

  @include mq(md) {
    width: 100%;
  }

  @include mq(sm) {
    width: 100%;
  }

  &__contents {
    //width: 100%;
    width: 1000px;
    height: 440px;
    margin: 0 auto;
    padding: 30px 0 0;
    //overflow: hidden;

    @include mq(md) {
      width: 95%;
      height: auto;
    }

    @include mq(sm) {
      width: calc(100% - 20px);
      height: auto;
    }
  }

  &__corporate-profile {
    float: left;
    text-align: left;
    line-height: 2;
    font-size: $FONT-SIZE-14;

    @include mq(md) {
    }

    @include mq(sm) {
      float: none;
    }

    p {}

    a {
      color: #fff;
    }
  }

  .contentsList {

    .first {
      width: 740px;
      text-align: left;
      float: left;
      @include reset;
      //overflow: hidden;

      @include mq(md) {
        width: 100%;
      }
      @include mq(sm) {
        width: 100%;
      }

      &__item {
        width: 200px;
        float: right;

        @include mq(md) {
          width: 30%;
          float: left;

          &:nth-of-type(n+3) {
            margin-left: 5%;
          }
        }
        @include mq(sm) {
          width: 100%;
          float: none;
        }

        &:first-child {
          width: 500px;
          min-height: 300px;
          float: left;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;

          @include mq(md) {
            width: 100%;
            min-height: auto;
          }
          @include mq(sm) {
            width: 100%;
            min-height: auto;
          }

          & > a {
          }

          .second {
            &__item {
              margin: 0 0 40px;
              line-height: 1;
              position: relative;

              @include mq(md) {
                flex-basis: 32%;
              }
              @include mq(sm) {
                margin: 0;
                &.active {
                  margin: 0 0 20px;
                }
              }

              a {
                margin: .5em 0;
                padding: 0 0 0 15px;
              }

              & + li {
                margin-top: 0;
              }
            }
          }
          .third {
            margin-top: 0;

            a {
              margin: 1em 0;
            }
          }
        }

        &:last-child {

          @include mq(md) {
          }
          @include mq(sm) {
          }

          & > a {
          }

          .second {
            &__item {
              //margin: 0 0 40px;
              line-height: 1;
              position: relative;

              @include mq(md) {
              }
              @include mq(sm) {
                margin: 0;
                &.active {
                  margin: 0 0 20px;
                }
              }
            }
          }
        }

        & > a {
          font-size: $FONT-SIZE-15;
          font-weight: bold;
          display: inline-block;
          line-height: 1;
          margin-bottom: 10px;
        }
      }
    }

    .second {
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      border-top: solid 2px #ccc;
      padding-top: 10px;

      @include mq(md) {
      }
      @include mq(sm) {
        display: block;
      }

      &__item {
        flex-basis: 240px;
        margin: .5em 0;
        padding: 0 0 0 8px;
        line-height: 1;

        @include mq(md) {
        }
        @include mq(sm) {
        }

        & > a {
          font-size: $FONT-SIZE-14;
          display: inline-block;
          line-height: 1;
          position: relative;
          padding: 0 0 0 15px;
          background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
          background-size: 5px;

          @include mq(md) {
            font-size: $FONT-SIZE-13;
          }
          @include mq(sm) {
            margin: .5em 0;
          }
        }

        & + li {
          margin-top: 0;
        }

        a {
        }

        i {
          display: none;
          position: absolute;
          top: .15em;
          right: 10px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          //background-color: #fff;
          font-size: $FONT-SIZE-16;
          overflow: hidden;
          border-radius: 10px;
          padding-left: 1px;

          @include mq(md) {
          }
          @include mq(sm) {
            display: block;
          }
        }

        &.active i {
          transform: rotate(-45deg);

        }
      }
    }

    .third {
      margin-top: 10px;

      @include mq(md) {
      }
      @include mq(sm) {
        display: none;
      }

      &__item {
        font-size: $FONT-SIZE-12;
        line-height: 1;
        margin: .5em 0;
        padding: 0 0 0 15px;

        @include mq(md) {
          font-size: $FONT-SIZE-11;
        }
        @include mq(sm) {
        }
      }

      a {
        position: relative;
        padding: 0 0 0 15px;
        background: url(#{$rootUrl}/images/common/arrow.png) center left no-repeat;
        background-size: 5px;
      }
    }
  }

  .col {
    width: 220px;
    float: right;

    @include mq(md) {
      float: none;
      margin-bottom: 40px;
    }

    @include mq(sm) {
      float: none;
      margin-bottom: 40px;
    }
  }
}



footer {
  .utility {
    margin: 10px 0 0;
    &__list {
      @include reset;
      text-align: left;
    }
    &__item {
      margin: 15px 0 0;
      padding: 0;
      line-height: 1;
      font-size: $FONT-SIZE-14;

      &:first-child {
        //width: 50px;
        //margin: 0;
      }
      .fa {
        display: none;
      }
    }
  }
}


/* =========================================================
BacktoTop
========================================================= */

.backtoTop {
  margin: 0;
  padding:10px 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: rgba($COLOR__ACCENT-PRIMARY,1);
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 997;

  @include mq(lg) {
    //position: fixed;
    //right: 20px;
    //bottom: 20px;
  }

  @include mq(md) {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  @include mq(sm) {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  i {
    display: inline-block;
    color: #fff;
    font-size: $FONT-SIZE-50;
    margin: -7px 0 0 4px;

    @include mq(sm) {
      font-size: $FONT-SIZE-32;
      margin: -2px 0 0 3px;
    }
  }
}

/* =========================================================
copyright
========================================================= */
.copyright {
  margin: 0;
  padding: 20px 50px;
  border-top: solid 2px #ccc;

  p {
    margin: 0 auto;
    font-size: $FONT-SIZE-14;
    clear: both;
  }
}
