
// ==========================================================================
// Navigation SCSS
// ==========================================================================



/* =========================================================
Global Navigation
========================================================= */

@keyframes slide {
  0% {
    transform: translate(0, -100%);
    transform-origin: top center;
  }
  100% {
    transform: translate(0, 0);
    transform-origin: top center;
  }
}
@keyframes close {
  0% {
    transform: translate(0, 0);
    transform-origin: top center;
  }
  100% {
    transform: translate(0, -100%);
    transform-origin: top center;
  }
}



nav.menu {
  position: relative;

  @include mq(md) {
    //z-index: -1;
  }
  @include mq(sm) {
    z-index: -1;
  }
}


.gnavi {
  position: relative;
  float: right;
  margin-right: 20px;
  margin-top: 15px;

  @include mq(md) {
    float: none;
    margin: 10px auto 0;
    display: inline-block;
  }

  @include mq(sm) {
    //display: none;
    background: rgba(#e3f5ff,1);
    //background: rgba($COLOR__ACCENT-PRIMARY,.83) url(#{$rootUrl}/images/home/water_bg.png) center top no-repeat;
    background-size: cover;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    //transform: translate(100%, 0);
    //transform-origin: top left;
    //padding-top: 50px;
    width: 100%;
    //height: 100%;
    overflow-y: scroll;
    margin: 0;
    z-index: -1;
    //display: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    opacity: 0;
    transition: all .5s ease 0s;
    visibility: hidden;
  }

  .is-active & {

    @include mq(md) {
      display: block;
      //height: auto;
      float: none;
      //animation: slide .2s ease-out 1 alternate forwards;
    }

    @include mq(sm) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: center;
      //height: auto;
      float: none;
      //animation: slide .2s ease-out 1 alternate forwards;
      //z-index: -1;
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-passive {

    @include mq(md) {
      display: block;
      //height: auto;
      float: none;
      //animation: close .2s ease-out 1 alternate forwards;
    }

    @include mq(sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      //height: auto;
      float: none;
      //animation: close .2s ease-out 1 alternate forwards;
      //z-index: -1;
      display: none;
      transition: none .5s ease 0s;
    }
  }

  &__list {
    //width: 540px;
    margin: 0;
    padding: 0;
    position: relative;
    float: left;
    z-index: 100;

    @include mq(md) {
    }

    @include mq(sm) {
      float: none;
      //width: calc(100% - 20px);
      width: 100%;
      margin: 0px;
      border-top: solid 1px rgba($COLOR__ACCENT-PRIMARY,1);
      border-bottom: solid 1px rgba($COLOR__ACCENT-PRIMARY,1);
      z-index: -1;
      margin-top: 160px;
    }
  }

  &__item {
    font-size: $FONT-SIZE-18;
    display: block;
    height: 50px;
    line-height: 50px;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;

    @include mq(md) {
    }

    @include mq(sm) {
      height: auto;
      float: none;
      margin: 0;
      text-align: left;
    }

    //&:nth-of-type(1) { width :108px;}

    & + & {
      //margin-left: calc(45px + 1em);
      margin-left: calc(28px + 1em);

      @include mq(md) {
        //margin-left: calc(10px + 1em);
      }

      @include mq(sm) {
        margin-left: 0;
        border-top: solid 1px rgba($COLOR__ACCENT-PRIMARY,1);
      }

      &:before {
        content: "/";
        position: absolute;
        //left: calc(-24px - .65em);
        left: calc(-14px - .65em);

        @include mq(md) {
          //left: calc(-7px - .65em);
        }

        @include mq(sm) {
          display: none;
        }
      }
    }
  }

  a {
    word-break: keep-all;
    @include mq(sm) {
      color: $COLOR__ACCENT-PRIMARY;
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 0 0 20px;
    }
  }

  &__link {
    display: inline-block;
    position: relative;

    @include mq(md) {
      //background-color: #fff;
      color: #fff;
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: left;
    }

    @include mq(sm) {
      //background-color: #fff;
      color: #fff;
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: left;
    }

    &:hover {

      @include mq(lg) {
        color: $COLOR__ACCENT-PRIMARY;
      }

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
  }

  // &__category {
  //   display: block;
  //   line-height: 70px;
  //   margin: 0;
  //   padding: 0 23px 0 22px;
  //   color: #422500;
  //   position: relative;
  //   letter-spacing: -.1px;
  //   //cursor: pointer;
  //
  //   &:after {
  //     content: "";
  //     height: 14px;
  //     border-right: solid 1px #422500;
  //     position: absolute;
  //     right: 0;
  //     top: 28px;
  //   }
  //
  //   &:hover {
  //     position: relative;
  //   }
  // }

  & .fa-chevron-right {
    display: none;

    @include mq(md) {
    }

    @include mq(sm) {
      display: inline-block;
      position: absolute;
      top: calc(50% - .5em);
      right: 2%;
      //display: none;
    }
  }
}

.gnavi.gnavi--products {
  .gnavi__list {

    @include mq(sm) {
      margin-top: 210px;
    }
  }
  .gnavi__item {
    & + li {
      @include mq(lg) {
        margin-left: calc(22px + 1em);
      }
      @include mq(md) {
        margin-left: calc(28px + 1em);
      }

      &:before {
        @include mq(lg) {
          left: calc(-11px - .65em);
        }
        @include mq(md) {
          left: calc(-14px - .65em);
        }
      }
    }
  }
}
