@charset "utf-8";
// ==========================================================================
// Base SCSS
// ==========================================================================
/*
* Project Name: ADEKA クリーンエイド株式会社
* Site URI:
* Description: ADEKA クリーンエイド株式会社 style
* Version: 1.0.0
* Author: ADEKA クリーンエイド株式会社
* CSS: base.css
*/

// ==========================================================================
// Declaration of variables
// ==========================================================================

@import "_foundation/variables";

// ==========================================================================
// Preset
// ==========================================================================

@import "_foundation/sanitize";

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

.wf {
  font-family: 'Montserrat','Noto Sans JP', sans-serif;
}

/* base.css */
// styling
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: $COLOR__ACCENT-PRIMARY;
  color: #fff;
}

html , body {
  min-width: 1000px;
  overflow: hidden;
  font-feature-settings : "palt" 1;
  font-size: 62.5%;
  letter-spacing: .15em;
  _letter-spacing: -0.1em;
  color: #444444;

  @include mq(md) {
    min-width: 100vw;
    overflow-x: hidden;
  }

  @include mq(sm) {
    min-width: 100vw;
    overflow-x: hidden;
  }
}

html {
  overflow: auto;
  background: #fff;
}

body {
  //overflow: hidden;
  //overflow-y: scroll;
  position: relative;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  font-family:$FONT-FAMILY__BASE;
  //font-weight: 100;
}


_:-ms-lang(x)::-ms-backdrop, body {
  font-family: $FONT-FAMILY__IE;
  letter-spacing: 0;
}


a {
  text-decoration: none;
  color: $COLOR__FONT;

  @include mq(lg) {
    //transition: all .3s;
  }
}
a:hover {
  color: $COLOR__ACCENT-PRIMARY;
  img {
    @include mq(lg) {
      //opacity: .5;
    }
  }
}
a:focus {
  outline: none;
}

a[href^="javascript:void(0)"] {
  cursor: default;
  color: $COLOR__FONT;
}

ul {
  padding-left:0;
}

li {
  list-style-type: none;
}

em {
  font-style: normal;
}

h1 , h2 , h3 , h4 , h5 , h6 {
}
h1 {}
h2 {}
h3 {}
h4 {}
h5 {}
h6 {}

dl,dt,dd {
  margin: 0;
  padding: 0;
}

input {
  font-family: $FONT-FAMILY__BASE;
}

figure {
  @include reset;
}

img {
  line-height: 1;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

%longtext {
  margin-top: -.5em;
  margin-bottom: 1em;

  & + & {
    margin-top: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.pc {

  @include mq(lg) {
    display: block;
  }

  @include mq(md) {
    display: none;
  }

  @include mq(sm) {
    display: none;
  }
}

.pc-pd {

  @include mq(lg) {
    display: block;
  }

  @include mq(md) {
    display: block;
  }

  @include mq(sm) {
    display: none;
  }
}

.sp-pd {

  @include mq(lg) {
    display: block;
  }

  @include mq(md) {
    display: block;
  }

  @include mq(lg) {
    display: none;
  }
}

.sp {

  @include mq(lg) {
    display: none;
  }

  @include mq(md) {
    display: none;
  }

  @include mq(sm) {
    display: block;
  }
}







// ==========================================================================
// colmn layout
// ==========================================================================

.flex {
  display: flex;
  flex-direction: row;
}

.left-col {
  float: left;
}

.right-col {
  float: right;
}

.single {
  text-align: center;
}

.col {}

.row {}

.button-area {
  text-align: center;
}

.button-area-left {
  text-align: left;
}

.button-area-right {
  text-align: right;
}

.scfixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

// ==========================================================================
// Import Vendor SCSS
// ==========================================================================

@import "_vendor/notosansjp";
@import "_vendor/swiper";
@import "_vendor/modaal";

// ==========================================================================
// Import Layout SCSS
// ==========================================================================

@import "_layout/header";
@import "_layout/footer";
@import "_layout/navigation";

// ==========================================================================
// Import Modules SCSS
// ==========================================================================

@import "_module/module";

// ==========================================================================
// Import State SCSS
// ==========================================================================

@import "_state/state";

// ==========================================================================
// Import Helper SCSS
// ==========================================================================

@import "_helper/helper";
