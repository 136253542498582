
// ==========================================================================
// Header SCSS
// ==========================================================================

/* =========================================================
header
========================================================= */

.header {
  width: 100%;
  min-height: 100px;
  @include reset;
  position: fixed;
  //position: relative;
  z-index: 997;
  background-color: rgba(255,255,255,1);
  top: 0;
  left: 0;
  //display: none;
  border-bottom: solid 1px #f9f9f9;

  @include mq(md) {
  }

  @include mq(sm) {
    min-height: 60px;
    border-bottom: 0;
  }

  &.is-active {

    @include mq(md) {
      //height: 100%;
      padding-bottom: 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;
    }

    @include mq(sm) {
      //height: 100%;
      padding-bottom: 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;
    }
  }

  &__inner {
    width: 100%;
    //max-width: 960px;
    height: 100px;
    margin: 0 auto;
    position: relative;

    @include mq(md) {
      height: auto;
    }

    @include mq(sm) {
      background-color: #fff;
      padding: 0 0 0 0;
      height: 60px;
    }
  }

  &__title {
    width: 25%;
    min-width: 260px;
    max-width: 520px;
    height: 100%;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    float: left;
    margin: 0 0 0 20px;
    padding: 0;
    position: relative;
    z-index: 1000;

    @include mq(md) {
      margin: 10px 0 0 20px;
      width: 260px;
      height: auto;
    }

    @include mq(sm) {
      margin: 20px 0 0 20px;
      min-width: auto;
      width: 180px;
      height: auto;
    }

    a {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1000;

      @include mq(md) {
        height: 24px;
      }

      @include mq(sm) {
        max-height: 20px;
      }
    }

    img {
      margin: 0 0 0;

      @include mq(md) {
      }

      @include mq(sm) {
      }
    }
  }
}

.hamburger-icon {
  display: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  //transition: all .4s ease 0;
  z-index: 9999;
  //opacity: 0;

  @include mq(md) {
  }

  @include mq(sm) {
    display: block;
    position: fixed;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    //background-color: #fff;
  }

  span {
    display: inline-block;
    position: absolute;
    left: 10px;
    transition: all .4s ease 0s;
    border-radius: 1.5px;
    @include reset;

    @include mq(md) {
    }

    @include mq(sm) {
      width: 32px;
      height: 3px;
      left: 14px;
    }

    &:nth-of-type(1) {
      background-color: $COLOR__SUB-PRIMARY;

      @include mq(md) {
      }

      @include mq(sm) {
        top: 18px;
      }
    }
    &:nth-of-type(2) {
      background-color: $COLOR__SUB-SECONDARY;

      @include mq(md) {
      }

      @include mq(sm) {
        top: 28px;
      }
    }
    &:nth-of-type(3) {
      background-color: $COLOR__SUB-TERTIARY;

      @include mq(md) {
      }

      @include mq(sm) {
        bottom: 18px;
      }
    }
  }

  &.is-active span {
    background-color: #999;
  }

  // ハンバーガーメニューアクティブ化　アニメーション
  &.is-active span:nth-of-type(1) {

    @include mq(md) {
    }

    @include mq(sm) {
  	  transform: translateY(11px) rotate(-45deg);
    }
  }
  &.is-active span:nth-of-type(2) {
  	opacity: 0;
  }
  &.is-active span:nth-of-type(3) {

    @include mq(md) {
    }

    @include mq(sm) {
  	  transform: translateY(-10px) rotate(45deg);
    }
  }
}

header {
  .utility {
    float: right;
    margin: 10px 20px 0 0;
    width: calc(100% - 50%);

    @include mq(md) {
      margin: 15px 20px 0 0;
      width: calc(100% - 50%);
    }

    @include mq(sm) {
      float: none;
      width: 100%;
      margin: 0px;
    }

    &__list {
      @include reset;

      @include mq(sm) {
        width: 100%;
        top: 60px;
        opacity: 0;
        z-index: 1;
        transition: none .5s ease 0s;
        visibility: hidden;
        position: absolute;
      }
    }
    &__item {
      float: right;
      font-size: $FONT-SIZE-12;

      @include mq(sm) {
        float: none;
        text-align: center;
        width: 100%;
      }
      &:first-child {
        width: 50px;
        margin-left: 20px;


        @include mq(sm) {
          width: 100%;
          line-height: 50px;
          height: 50px;
          display: block;
          margin-left: 0;
          img {width: 80px;margin: 0 auto;}
        }
        a {

          @include mq(sm) {
            padding: 9px 0 10px;
          }
        }
      }
      a {
        line-height: 20px;
        word-break: keep-all;

        @include mq(sm) {
          width: 100%;
          line-height: 50px;
          display: block;
          color: $COLOR__ACCENT-PRIMARY;
        }
      }
      .fa {
        display: none;
      }
    }
  }
}

header .is-active .utility {

  @include mq(md) {
  }

  @include mq(sm) {
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    float: none;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }

  &__item {
    & + li {
        margin-left: 0;
      a {
        line-height: 50px;
        border-top: solid 1px rgba($COLOR__ACCENT-PRIMARY,1);
        color: $COLOR__ACCENT-PRIMARY;
      }
    }
  }
}



.utility--products {
  float: right;
  margin: 10px 20px 0 0;
  width: calc(100% - 50%);

  @include mq(md) {
    margin: 15px 20px 0 0;
    width: calc(100% - 50%);
  }

  @include mq(sm) {
    float: none;
    width: 100%;
    margin: 0px;
  }

  &__list {
    @include reset;
    float: right;
    display: flex;
    flex-direction: row;

    @include mq(sm) {
      width: 100%;
      top: 60px;
      opacity: 0;
      z-index: 1;
      transition: none .5s ease 0s;
      visibility: hidden;
      position: absolute;
    }
  }
  &__item {
    width: auto;
    float: none;
    font-size: $FONT-SIZE-12;

    @include mq(sm) {
      float: none;
      text-align: center;
      width: 100%;
    }
    &:first-child {
      a {
        width: auto;
        line-height: 20px;
        margin-right: 20px;
        padding: 3px 5px 3px 5px;
        color: $COLOR__FONT;
        font-size: $FONT-SIZE-16;

        @include mq(sm) {
          display: block;
          background-position: 20px center;
          line-height: 44px;
          padding: 3px 5px 3px 20px;
          text-align: left;
        }
      }
    }
    &:nth-of-type(2) {
      @include mq(sm) {
        width: 100%;
        line-height: 50px;
        height: 50px;
        display: block;
        margin-left: 0;
        border: none;
        border-top: solid 1px #ccc;

        img {width: 80px;margin: 0 auto;}
      }
      a {
        background-image: url(#{$rootUrl}/images/common/icon/account_b.png);
        background-size: 10px;
        background-position: 5px center;
        line-height: 20px;
        margin-right: 20px;
        padding: 3px 5px 3px 20px;
        color: $COLOR__FONT;
        font-size: $FONT-SIZE-16;

        @include mq(sm) {
          display: block;
          background-position: 20px center;
          line-height: 44px;
          padding: 3px 5px 3px 40px;
          text-align: left;
        }
      }
    }
    &:nth-of-type(3) {
      border: solid 1px #ccc;

      @include mq(sm) {
        border: none;
        border-top: solid 1px #ccc;
      }
      a {
        background-image: url(#{$rootUrl}/images/common/icon/back.png);
        background-size: 10px;
        background-position: 5px center;
        line-height: 20px;
        padding: 2px 5px 2px 20px;
        border-radius: 3px;
        color: #aaa;

        @include mq(sm) {
          display: block;
          background-position: 20px center;
          line-height: 44px;
          padding: 2px 5px 2px 40px;
          text-align: left;
        }
      }
    }

    // &:nth-of-type(3) {
    //   border: solid 1px #ccc;
    //
    //   @include mq(sm) {
    //     border: none;
    //     border-top: solid 1px #ccc;
    //   }
    // }
    .fa {
      display: none;
    }
  }
}

header .is-active .utility--products {

  @include mq(md) {
  }

  @include mq(sm) {
    float: none;
    width: 100%;
    margin: 0px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    float: none;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }

  &__item {
    & + li {
        margin-left: 0;
      a {
        line-height: 50px;
        //color: #999;

        @include mq(sm) {
          border: none;
          display: block;
          background-position: 20px center;
          padding: 2px 5px 2px 40px;
          text-align: left;
        }
      }
    }
  }
}
