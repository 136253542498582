// ==========================================================================
// Compornents Style :: Module SCSS
// ==========================================================================


/* =========================================================
#button
========================================================= */

// .button {
//   padding: .75em 1.5em;
//   display: inline-block;
//   border-radius: 3px;
//   line-height: 1;
//   cursor: pointer;
//   color: #eee;
//   background-color: $COLOR__ACCENT-SECONDARY;
//   font-size: $FONT-SIZE-14;
//
//   &:hover {
//     color: #eee;
//
//     @include mq(lg) {
//       box-shadow: 0 2px 1px 0 rgba(0,0,0,.25);
//     }
//   }
//
//   &--primary {
//     background-color: #1e3661;
//   }
//
//   &--secandary {
//     background-color: #b85321;
//   }
//
//   &--ghost {
//     border:solid 1px #eee;
//   }
//
//   &--pagenation {
//     border:solid 1px #000;
//   }
// }

.button {
  background: transparent;
  display: inline-block;
  font-size: $FONT-SIZE-14;

  &--round {
    padding: 12px 30px;
    line-height: 1;
    border-radius: 50vh;

    @include mq(lg) {}
    @include mq(md) {}
    @include mq(sm) {
      display: block;
      text-align: center;
    }
  }

  &--square {
    line-height: 1;
    padding: 8px 50px;
  }

  &--fillWhite {
    background-color: #fff;
    color: $COLOR__ACCENT-PRIMARY;

    &:hover {

      @include mq(lg) {
        color: #fff;
        background-color: $COLOR__ACCENT-PRIMARY;
      }
      @include mq(md) {}
      @include mq(sm) {}
    }
  }

  &--lineWhite {
    border: solid 1px #fff;
    color: #fff;

    &:hover {

      @include mq(lg) {
        background-color: #fff;
        color: $COLOR__ACCENT-PRIMARY;
      }
      @include mq(md) {}
      @include mq(sm) {}
    }
  }

  &--fillBlue {
    background-color: $COLOR__ACCENT-PRIMARY;
    color: #fff;

    &:hover {

      @include mq(lg) {
        background-color: darken($COLOR__ACCENT-PRIMARY, 10%);
      }
      @include mq(md) {}
      @include mq(sm) {}
    }
  }

  &--lineBlue {
    border: solid 1px $COLOR__ACCENT-PRIMARY;
    color: $COLOR__ACCENT-PRIMARY;

    &:hover {

      @include mq(lg) {
        background-color: $COLOR__ACCENT-PRIMARY;
        color: #fff;
      }
      @include mq(md) {}
      @include mq(sm) {}
    }
  }

}



.label {
  background: transparent;
  color: #fff;
  font-size: $FONT-SIZE-10;
  padding: 3px 10px;
  line-height: 1;
  border-radius: 2px;
  display: inline-block;

  &--informaition {
    @extend .label;
    background: $COLOR__LABEL__01;
  }

  &--news {
    @extend .label;
    background: $COLOR__LABEL__02;
  }
}








.qanda {
    padding: 40px 0;
    margin: 0 0 160px;
    background-color: #e3f5ff;

  &__item {
    background-color: #fff;
    text-align: left;
    max-width: 1000px;
    margin: 0 auto 20px;
    transition: box-shadow .5s ease 0s;

    @include mq(md) {
      width: 95%;
      margin: 0 auto 10px;
    }
    @include mq(sm) {
      width: 95%;
      margin: 0 auto 10px;
    }

    &:hover {
      box-shadow: 4px 0 16px rgba(0,0,0,.1);
      transition: box-shadow .5s ease 0s;
    }
  }

  &__question {
    padding: 0 80px;
    position: relative;
    min-height: 60px;
    cursor: pointer;

    @include mq(md) {
      padding: 0 60px 0 80px;
    }
    @include mq(sm) {
      padding: 0 30px 0 50px;
      position: relative;
      min-height: 50px;
      display: flex;
      justify-content: flex-start;
      //align-items: stretch;
      //align-content: stretch;
    }

    span {
      background-color: $COLOR__ACCENT-PRIMARY;
      color: #fff;
      display: block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: $FONT-SIZE-36;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;

      @include mq(md) {}
      @include mq(sm) {
        font-size: $FONT-SIZE-20;
        width: 40px;
        height: 100%;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    h3 {
      width: 840px;
      min-height: 60px;
      height: 60px;
      font-size: $FONT-SIZE-18;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      //align-content: center;
      justify-content: space-around;
      color: $COLOR__ACCENT-PRIMARY;

      .block {
        width: 100%;
      }

      @include mq(md) {
        width: 100%;
      }
      @include mq(sm) {
        width: 100%;
        min-height: 40px;
        font-size: $FONT-SIZE-16;
        padding: 5px 0;
        height: auto;
      }
    }

    .switch {
      display: block;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0;
      right: 0;
      background: url(#{$rootUrl}/images/sanitation/arrow_down.png) center no-repeat;
      background-size: 16px;
      transition: transform .5s ease 0s;

      @include mq(md) {}
      @include mq(sm) {
        width: 30px;
        height: 100%;
        background-size: 10px;
      }
    }

    &.active .switch {
      //transform: rotate(0deg);
      transform: rotate(180deg);
    }
  }

  &__answer {
    padding: 10px 20px 10px 80px;
    position: relative;
    min-height: 70px;
    display: none;
    transition: display .5s ease 0s;

    @include mq(md) {}
    @include mq(sm) {
      padding: 10px 10px 10px 50px;
    }

    span {
      background-color: $COLOR__SUB-SECONDARY;
      color: #fff;
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: $FONT-SIZE-24;
      font-weight: bold;
      position: absolute;
      top: 10px;
      left: 10px;

      @include mq(md) {}
      @include mq(sm) {
        font-size: $FONT-SIZE-20;
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .answerBody {
      width: 900px;
      font-size: $FONT-SIZE-18;
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 0;
      color: $COLOR__SUB-SECONDARY;
      //text-align: left;

      @include mq(md) {
        width: 100%;
      }
      @include mq(sm) {
        width: 100%;
        min-height: 30px;
        font-size: $FONT-SIZE-16;
        //padding: 5px 0;
      }
    }

    .block {
      color: $COLOR__SUB-SECONDARY;
      font-size: $FONT-SIZE-18;
      margin-bottom: 20px;
      width: 100%;

      @include mq(md) {}
      @include mq(sm) {
        font-size: $FONT-SIZE-16;
      }

      &--img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        width: auto;
        height: auto;

        @include mq(md) {}
        @include mq(sm) {
          flex-direction: column;

          img + img {
            margin-top: 10px;
          }
        }
      }
    }

    a {
      color: $COLOR__SUB-SECONDARY;
      font-size: $FONT-SIZE-18;
      text-decoration: underline;

      @include mq(md) {}
      @include mq(sm) {
        font-size: $FONT-SIZE-16;
      }
    }

    .principle {
      font-size: $FONT-SIZE-16;
    }

    img {
      @include mq(lg) {
        width: auto;
      }
    }
  }
}

/* =========================================================
#pagenation
========================================================= */

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 80px;
  font-size: $FONT-SIZE-14;
  @include mq(sm) {
    font-size: $FONT-SIZE-10;
  }

  table + & {
    margin-top: -40px;
  }

  li.page-item {

    & + li {
      margin-left: 10px;
    }

    .page-link {
      min-width: 30px;
      display: inline-block;
      border: solid 1px #999;
      //border-radius: 3px;
      padding: 6px 10px;
      text-align: center;
      color: #999;
      @include mq(sm) {
        padding: 3px 5px;
        min-width: 20px;
      }

      &:hover {
        border: solid 1px $COLOR__ACCENT-PRIMARY;
        color: $COLOR__ACCENT-PRIMARY;
      }
    }
    &.active {
      .page-link {
        border: solid 1px $COLOR__ACCENT-PRIMARY;
        background-color: $COLOR__ACCENT-PRIMARY;
        color: #fff;
      }
    }
  }
}

/* =========================================================
#icon
========================================================= */

.icon {
  padding: .5em 1.25em;
  display: inline-block;
  line-height: 1;
  border-radius: 1em;

  &--required {
    background: $COLOR__ALERT;
    color: #fff;
    font-size: $FONT-SIZE-10;
  }
}

/* =========================================================
#modal
========================================================= */

.modal{
  display: none;
}












/* =========================================================
#color
========================================================= */


.accentcolor--primary {
  color: #ff8300;
}

.accentcolor--secondary {
  color: #422500;

  &-bold {
    color: #422500;
    font-weight: bold;
  }
}

.alert-color {
  color: #cc0000;
}

/* =========================================================
#error
========================================================= */

.err {
  text-align: left;
  margin-top: 0;
  color: $COLOR__ALERT;
}



.arrow-label {
  > * {
    padding: 0 10px;
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
    background-color: #DFDED4;
    box-sizing: border-box;
  }

  :first-child {
    font-size: 14px;
    color: #fff;
    position: relative;
    @include mq(sm) {
        font-size: 12px;
    }
  }

  :first-child::after {
    content: "";
    position: absolute;
    margin-top: 1em;
    border-top: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-left: 0.5em solid transparent;
    right: -0.5em;
  }

  :last-child {
    padding-left: 15px;
    width: 190px;
    font-size: 15px;
    font-weight: bold;
    @include mq(sm) {
      width: 180px;
      font-size: 13px;
    }
  }

  .orange {
    background-color: #FF832C;
  }
  .orange:after {
    border-left-color: #FF832C;
  }
  .red {
    background-color: #FF2D16;
  }
  .red:after {
    border-left-color: #FF2D16;
  }
  .green {
    background-color: #019565;
  }
  .green:after {
    border-left-color: #019565;
  }
  .blue {
    background-color: #479FD6;
  }
  .blue:after {
    border-left-color: #479FD6;
  }

}
