
// ==========================================================================
// Helper SCSS
// ==========================================================================

/* =========================================================
Helper
========================================================= */

.format {
  background: none !important ;
  margin: 0 !important ;
  padding: 0 !important ;
  border: none !important ;
}

.noborder {
  border: none !important;
}

.fsize8 { font-size: $FONT-SIZE-8 ; }
.fsize9 { font-size: $FONT-SIZE-9 ; }
.fsize10 { font-size: $FONT-SIZE-10 ; }
.fsize11 { font-size: $FONT-SIZE-11 ; }
.fsize12 { font-size: $FONT-SIZE-12 ; }
.fsize13 { font-size: $FONT-SIZE-13 ; }
.fsize14 { font-size: $FONT-SIZE-14 ; }
.fsize15 { font-size: $FONT-SIZE-15 ; }
.fsize16 { font-size: $FONT-SIZE-16 ; }
.fsize17 { font-size: $FONT-SIZE-17 ; }
.fsize18 { font-size: $FONT-SIZE-18 ; }
.fsize19 { font-size: $FONT-SIZE-19 ; }
.fsize20 { font-size: $FONT-SIZE-20 ; }
.fsize21 { font-size: $FONT-SIZE-21 ; }
.fsize22 { font-size: $FONT-SIZE-22 ; }
.fsize23 { font-size: $FONT-SIZE-23 ; }
.fsize24 { font-size: $FONT-SIZE-24 ; }
.fsize25 { font-size: $FONT-SIZE-25 ; }
.fsize26 { font-size: $FONT-SIZE-26 ; }
.fsize27 { font-size: $FONT-SIZE-27 ; }
.fsize28 { font-size: $FONT-SIZE-28 ; }
.fsize29 { font-size: $FONT-SIZE-29 ; }
.fsize30 { font-size: $FONT-SIZE-30 ; }
.fsize31 { font-size: $FONT-SIZE-31 ; }
.fsize32 { font-size: $FONT-SIZE-32 ; }


.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }

.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }

.mr10 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }

.mb0 { margin-bottom: 0; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb50 { margin-bottom: 50px; }



.fwbold { font-weight: bold ; }          // 太字
.fwnormal { font-weight: normal ; }      // 細字
.ffonospace { font-family: monospace ; } // 等幅
.fsnormal { font-style: normal ; }       // 標準
.fsitalic { font-style: italic ; }       // イタリック
.fsoblique { font-style: oblique ; }     // 斜体


// フォント改行スペース
.lheight10 { line-height: 1 ; }   // 100%
.lheight12 { line-height: 1.2 ; } // 120%
.lheight13 { line-height: 1.3 ; } // 130%
.lheight14 { line-height: 1.4 ; } // 140%
.lheight15 { line-height: 1.5 ; } // 150%
.lheight16 { line-height: 1.6 ; } // 160%
.lheight17 { line-height: 1.7 ; } // 170%
.lheight18 { line-height: 1.8 ; } // 180%
.lheight20 { line-height: 2 ; }   // 200%
.lheight25 { line-height: 2.5 ; } // 250%
.lheight30 { line-height: 3 ; }   // 300%


// テキスト位置
.tleft,
.tleft th,
.tleft td { text-align: left !important ; }     // 左寄せ
.tright,
.tright th,
.tright td { text-align: right !important ; }   // 右寄せ
.tcenter,
.tcenter th,
.tcenter td { text-align: center !important ; } // 中央寄せ


// ボーダー
.bsolid1e {border:solid 1px #eee;}
.bsolid2e {border:solid 2px #eee;}
.bsolid3e {border:solid 3px #eee;}

.bsolid1d {border:solid 1px #ddd;}
.bsolid2d {border:solid 2px #ddd;}
.bsolid3d {border:solid 3px #ddd;}

.bsolid1c {border:solid 1px #ccc;}
.bsolid2c {border:solid 2px #ccc;}
.bsolid3c {border:solid 3px #ccc;}

.bdashed1e {border:dashed 1px #eee;}
.bdashed2e {border:dashed 2px #eee;}
.bdashed3e {border:dashed 3px #eee;}

.bdashed1d {border:dashed 1px #ddd;}
.bdashed2d {border:dashed 2px #ddd;}
.bdashed3d {border:dashed 3px #ddd;}

.bdashed1c {border:dashed 1px #ccc;}
.bdashed2c {border:dashed 2px #ccc;}
.bdashed3c {border:dashed 3px #ccc;}


// 垂直位置寄せ
.vtop,
.vtop img,
.vtop th,
.vtop td { vertical-align: top !important ; }       // 上寄せ
.vmiddle,
.vmiddle img,
.vmiddle th,
.vmiddle td { vertical-align: middle !important ; } // 中央寄せ
.vbottom,
.vbottom img,
.vbottom th,
.vbottom td { vertical-align: bottom !important ; } // 下寄せ


// 水平位置寄せと解除
.fleft { float: left ; }   // 左回り込み
.fright { float: right ; } // 右回り込み
.cboth { clear: both ; }   // 解除

//
// // 各上下左右余白指定
// .mtop0 { margin-top: 0px !important ; }
// .mtop5 { margin-top: 5px !important ; }
// .mtop10 { margin-top: 10px !important ; }
// .mtop15 { margin-top: 15px !important ; }
// .mtop20 { margin-top: 20px !important ; }
// .mtop25 { margin-top: 25px !important ; }
// .mtop30 { margin-top: 30px !important ; }
// .mtop35 { margin-top: 35px !important ; }
// .mtop40 { margin-top: 40px !important ; }
// .mtop45 { margin-top: 45px !important ; }
// .mtop50 { margin-top: 50px !important ; }
// .mtop60 { margin-top: 60px !important ; }
// .mtop70 { margin-top: 70px !important ; }
// .mtop80 { margin-top: 80px !important ; }
// .mtop90 { margin-top: 90px !important ; }
// .mtop100 { margin-top: 100px !important ; }
// .mbottom0 { margin-bottom: 0px !important ; }
// .mbottom5 { margin-bottom: 5px !important ; }
// .mbottom10 { margin-bottom: 10px !important ; }
// .mbottom15 { margin-bottom: 15px !important ; }
// .mbottom20 { margin-bottom: 20px !important ; }
// .mbottom25 { margin-bottom: 25px !important ; }
// .mbottom30 { margin-bottom: 30px !important ; }
// .mbottom35 { margin-bottom: 35px !important ; }
// .mbottom40 { margin-bottom: 40px !important ; }
// .mbottom45 { margin-bottom: 45px !important ; }
// .mbottom50 { margin-bottom: 50px !important ; }
// .mbottom60 { margin-bottom: 60px !important ; }
// .mbottom70 { margin-bottom: 70px !important ; }
// .mbottom80 { margin-bottom: 80px !important ; }
// .mbottom90 { margin-bottom: 90px !important ; }
// .mbottom100 { margin-bottom: 100px !important ; }
// .mleft0 { margin-left: 0px !important ; }
// .mleft5 { margin-left: 5px !important ; }
// .mleft10 { margin-left: 10px !important ; }
// .mleft15 { margin-left: 15px !important ; }
// .mleft20 { margin-left: 20px !important ; }
// .mleft25 { margin-left: 25px !important ; }
// .mleft30 { margin-left: 30px !important ; }
// .mleft35 { margin-left: 35px !important ; }
// .mleft40 { margin-left: 40px !important ; }
// .mleft45 { margin-left: 45px !important ; }
// .mleft50 { margin-left: 50px !important ; }
// .mleft60 { margin-left: 60px !important ; }
// .mleft70 { margin-left: 70px !important ; }
// .mleft80 { margin-left: 80px !important ; }
// .mleft90 { margin-left: 90px !important ; }
// .mleft100 { margin-left: 100px !important ; }
// .mright0 { margin-right: 0px !important ; }
// .mright5 { margin-right: 5px !important ; }
// .mright10 { margin-right: 10px !important ; }
// .mright12 { margin-right: 12px !important ; }
// .mright15 { margin-right: 15px !important ; }
// .mright20 { margin-right: 20px !important ; }
// .mright25 { margin-right: 25px !important ; }
// .mright30 { margin-right: 30px !important ; }
// .mright35 { margin-right: 35px !important ; }
// .mright40 { margin-right: 40px !important ; }
// .mright45 { margin-right: 45px !important ; }
// .mright50 { margin-right: 50px !important ; }
// .mright60 { margin-right: 60px !important ; }
// .mright70 { margin-right: 70px !important ; }
// .mright80 { margin-right: 80px !important ; }
// .mright90 { margin-right: 90px !important ; }
// .mright100 { margin-right: 100px !important ; }
//
// .mtop-10 { margin-top: -10px; }
// .mtop-20 { margin-top: -20px; }


// 各辺寄せ指定
.topFit,
.leftFit , .rightFit , .bottomFit {
}
.topFit{
}
.leftFit{
}
.rightFit{
}
.bottomFit {
}

// 文字詰め
.kerning {
  letter-spacing: -.5px;
}


.cf {

  &:before ,
  &:after {
    content: "";// micro clearfix hack
    display: table;// For modern browsers
  }

  &:after {
    clear: both;
  }

  zoom: 1;// For IE 6/7 (trigger hasLayout)
}

.container:before ,
.container:after ,
.box:before ,
.box:after ,
article:before ,
article:after ,
header:before ,
header:after ,
footer:before ,
footer:after {
  content:"";
  display: table
}

.container:after ,
.box:after ,
article:after ,
header:after ,
footer:after {
  clear: both;
}

.container ,
.box ,
article ,
header ,
footer {
  zoom: 1;
}

.textLimit {
  list-style-position: inside;

  &__item {
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.highlighter {

  &--pnk {
    background: linear-gradient(transparent 30%, #fbd 30%);
  }

  &--ylw {
    background: linear-gradient(transparent 30%, #ffb 30%);
  }

  &--ylw {
    background: linear-gradient(transparent 70%, #ff0 30%);
    display: inline;
  }
}

.hanging {
  padding-left: 1em!important;
  display: inline-block;
  text-indent: -1em;
}
