
// ==========================================================================
// State SCSS
// ==========================================================================

.gnavi__link.is-current {
}



@keyframes show{
  from{
    transform : translate(0, 20px);
    transition : transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 0;
  }
  to{
    transform : translate(0, 0);
    transition : transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 1;
  }
}
@keyframes hide{
  from{
    transform : translate(0, 0);
    transition : transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 1;
  }
  to{
    transform : translate(0, 20px);
    transition : transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 0;
  }
}

.disable {
  display: none;
}

.is-active {}


.fadein {

  @include mq(lg) {
    opacity : 0;
    transform : translate(0, 50px);
  }

  @include mq(md) {
    opacity : 0;
    transform : translate(0, 50px);
  }

  &.scrollin {

    @include mq(lg) {
      opacity : 1;
      transform : translate(0, 0);
      transition: transform .6s ease 0s,opacity .6s ease 0s;
    }

    @include mq(md) {
      opacity : 1;
      transform : translate(0, 0);
      transition: transform .6s ease 0s,opacity .6s ease 0s;
    }
  }
}

.fadein.three-icon {
  i {
    overflow: hidden;
    height: 30px;
    span {
      &:nth-of-type(1) {
        @include mq(lg) {
          margin-top: 30px;
        }
        @include mq(md) {
          margin-top: 30px;
        }
      }
      &:nth-of-type(2) {
        @include mq(lg) {
          margin-top: 30px;
        }
        @include mq(md) {
          margin-top: 30px;
        }
      }
      &:nth-of-type(3) {
        @include mq(lg) {
          margin-top: 30px;
        }
        @include mq(md) {
          margin-top: 30px;
        }
      }
    }
  }
}
.scrollin.three-icon {
  i {
    span {
      &:nth-of-type(1) {
        @include mq(lg) {
          margin-top: 0;
          transition: margin-top .3s ease .3s;
        }
        @include mq(md) {
          margin-top: 0;
          transition: margin-top .3s ease .3s;
        }
      }
      &:nth-of-type(2) {
        @include mq(lg) {
          margin-top: 0;
          transition: margin-top .3s ease .6s;
        }
        @include mq(md) {
          margin-top: 0;
          transition: margin-top .3s ease .6s;
        }
      }
      &:nth-of-type(3) {
        @include mq(lg) {
          margin-top: 0;
          transition: margin-top .3s ease .9s;
        }
        @include mq(md) {
          margin-top: 0;
          transition: margin-top .3s ease .9s;
        }
      }
    }
  }
}

.type2 {

  @include mq(lg) {
    transition-delay:200ms;
  }

  @include mq(md) {
    transition-delay:200ms;
  }
}

.type3 {

  @include mq(lg) {
    transition-delay:400ms;
  }

  @include mq(md) {
    transition-delay:400ms;
  }
}

.slow {

  @include mq(lg) {
    transition-delay:200ms;
    transition : all 1s;
  }

  @include mq(md) {
    transition-delay:200ms;
    transition : all 1s;
  }
}

.slowx {

  @include mq(lg) {
    transition-delay:200ms;
    transition : all 1.5s;
  }

  @include mq(md) {
    transition-delay:200ms;
    transition : all 1.5s;
  }
}
