@charset "UTF-8";
/*
* Project Name: ADEKA クリーンエイド株式会社
* Site URI:
* Description: ADEKA クリーンエイド株式会社 style
* Version: 1.0.0
* Author: ADEKA クリーンエイド株式会社
* CSS: base.css
*/
/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none;
}

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 4 */
}

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%;
}

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0;
}

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

.wf {
  font-family: 'Montserrat','Noto Sans JP', sans-serif;
}

/* base.css */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: #07ace9;
  color: #fff;
}

html, body {
  min-width: 1000px;
  overflow: hidden;
  font-feature-settings: "palt" 1;
  font-size: 62.5%;
  letter-spacing: .15em;
  _letter-spacing: -0.1em;
  color: #444444;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  html, body {
    min-width: 100vw;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  html, body {
    min-width: 100vw;
    overflow-x: hidden;
  }
}

html {
  overflow: auto;
  background: #fff;
}

body {
  position: relative;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  font-family: "Montserrat", "Noto Sans JP", "Lucida Grande", "Hiragino Kaku Gothic ProN", "Yu Gothic", YuGothic, Meiryo, sans-serif;
}

_:-ms-lang(x)::-ms-backdrop, body {
  font-family: メイリオ, Meiryo, "Lucida Grande", "Hiragino Kaku Gothic ProN", sans-serif;
  letter-spacing: 0;
}

a {
  text-decoration: none;
  color: #444444;
}

a:hover {
  color: #07ace9;
}

a:focus {
  outline: none;
}

a[href^="javascript:void(0)"] {
  cursor: default;
  color: #444444;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
}

em {
  font-style: normal;
}

dl, dt, dd {
  margin: 0;
  padding: 0;
}

input {
  font-family: "Montserrat", "Noto Sans JP", "Lucida Grande", "Hiragino Kaku Gothic ProN", "Yu Gothic", YuGothic, Meiryo, sans-serif;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  line-height: 1;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

@media screen and (min-width: 1000px) {
  .pc {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .pc {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .pc-pd {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .pc-pd {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc-pd {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .sp-pd {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .sp-pd {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .sp-pd {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .sp {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.flex {
  display: flex;
  flex-direction: row;
}

.left-col {
  float: left;
}

.right-col {
  float: right;
}

.single {
  text-align: center;
}

.button-area {
  text-align: center;
}

.button-area-left {
  text-align: left;
}

.button-area-right {
  text-align: right;
}

.scfixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}

/**
 * Swiper 3.4.0
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: October 16, 2016
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  background: #ddd;
  opacity: 1;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #63241e;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 9px;
}

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube, .swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

/* =========================================================
Override Setting
========================================================= */
.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 100%;
  max-width: 1680px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}

.swiper-slide a {
  display: block;
  width: 100%;
}

.swiper-slide img {
  width: 100%;
}

.swiper-button-next-mv, .swiper-button-prev-mv {
  background-color: rgba(99, 36, 30, 0.2);
  font-size: 2rem;
  text-indent: 1px;
  position: absolute;
  top: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-top: -40px;
  z-index: 10;
  cursor: pointer;
  background-position: center;
  transition: all .3s;
  border-radius: 40px;
  color: #fff;
}

.swiper-button-next-mv:hover, .swiper-button-prev-mv:hover {
  background-color: rgba(99, 36, 30, 0.5);
}

.swiper-button-next-mv {
  right: 50%;
  margin-right: -540px;
}

.swiper-button-prev-mv {
  left: 50%;
  margin-left: -540px;
  transform: rotate(180deg);
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 3.125%;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ccc;
}

.swiper-pagination-bullet-active {
  background: #fe000b;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .swiper-button-next-mv {
    right: 2%;
    left: auto;
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-button-prev-mv {
    left: 2%;
    right: auto;
    margin-left: 0;
    margin-right: 0;
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 767px) {
  .swiper-container {
    margin: 0;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
  }
  .swiper-slide {
    width: 100%;
  }
  .swiper-slide img {
    min-width: auto;
  }
  .swiper-button-next-mv, .swiper-button-prev-mv {
    display: block;
  }
  .swiper-button-next-mv {
    right: 25px;
    text-indent: 2px;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    border-radius: 25px;
  }
  .swiper-button-prev-mv {
    left: 25px;
    text-indent: 2px;
    right: auto;
    margin-left: 0;
    margin-right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    border-radius: 25px;
    transform: rotate(180deg);
  }
}

.slider2 .swiper-container {
  width: 100%;
  height: 100%;
  max-height: 640px;
  margin: 0;
  padding: 0;
}

.slider2 .swiper-slide {
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-top: 38.09523%;
  overflow: hidden;
  background: #eee;
}

.slider2 .slide00 {
  background: url(/images/home/slide_04.jpg) center top no-repeat;
  background-size: cover;
}

.slider2 .slide01 {
  background: url(/images/home/slide_01.jpg) center top no-repeat;
  background-size: cover;
}

.slider2 .slide02 {
  background: url(/images/home/slide_02.jpg) center top no-repeat;
  background-size: cover;
}

.slider2 .slide03 {
  background: url(/images/home/slide_00.jpg) center top no-repeat;
  background-size: cover;
}

.slider2 .slide04 {
  background: url(/images/home/slide_03.jpg) center top no-repeat;
  background-size: cover;
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}

.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}

.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}

.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}

.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}

.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 0;
}

.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}

.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}

.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-close:before {
  transform: rotate(-45deg);
}

.modaal-close:after {
  transform: rotate(45deg);
}

.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .modaal-content-container {
    padding: 10px;
  }
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}

.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}

.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}

.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}

.modaal-gallery-item img {
  display: block;
}

.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}

.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}

.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}

.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}

.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

/* =========================================================
header
========================================================= */
.header {
  width: 100%;
  min-height: 100px;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 997;
  background-color: white;
  top: 0;
  left: 0;
  border-bottom: solid 1px #f9f9f9;
}

@media screen and (max-width: 767px) {
  .header {
    min-height: 60px;
    border-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .header.is-active {
    padding-bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 767px) {
  .header.is-active {
    padding-bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.header__inner {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .header__inner {
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .header__inner {
    background-color: #fff;
    padding: 0 0 0 0;
    height: 60px;
  }
}

.header__title {
  width: 25%;
  min-width: 260px;
  max-width: 520px;
  height: 100%;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  float: left;
  margin: 0 0 0 20px;
  padding: 0;
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .header__title {
    margin: 10px 0 0 20px;
    width: 260px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .header__title {
    margin: 20px 0 0 20px;
    min-width: auto;
    width: 180px;
    height: auto;
  }
}

.header__title a {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .header__title a {
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .header__title a {
    max-height: 20px;
  }
}

.header__title img {
  margin: 0 0 0;
}

.hamburger-icon {
  display: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  z-index: 9999;
}

@media screen and (max-width: 767px) {
  .hamburger-icon {
    display: block;
    position: fixed;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
  }
}

.hamburger-icon span {
  display: inline-block;
  position: absolute;
  left: 10px;
  transition: all .4s ease 0s;
  border-radius: 1.5px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .hamburger-icon span {
    width: 32px;
    height: 3px;
    left: 14px;
  }
}

.hamburger-icon span:nth-of-type(1) {
  background-color: #0dadf1;
}

@media screen and (max-width: 767px) {
  .hamburger-icon span:nth-of-type(1) {
    top: 18px;
  }
}

.hamburger-icon span:nth-of-type(2) {
  background-color: #0039ca;
}

@media screen and (max-width: 767px) {
  .hamburger-icon span:nth-of-type(2) {
    top: 28px;
  }
}

.hamburger-icon span:nth-of-type(3) {
  background-color: #09c0a0;
}

@media screen and (max-width: 767px) {
  .hamburger-icon span:nth-of-type(3) {
    bottom: 18px;
  }
}

.hamburger-icon.is-active span {
  background-color: #999;
}

@media screen and (max-width: 767px) {
  .hamburger-icon.is-active span:nth-of-type(1) {
    transform: translateY(11px) rotate(-45deg);
  }
}

.hamburger-icon.is-active span:nth-of-type(2) {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .hamburger-icon.is-active span:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
  }
}

header .utility {
  float: right;
  margin: 10px 20px 0 0;
  width: calc(100% - 50%);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  header .utility {
    margin: 15px 20px 0 0;
    width: calc(100% - 50%);
  }
}

@media screen and (max-width: 767px) {
  header .utility {
    float: none;
    width: 100%;
    margin: 0px;
  }
}

header .utility__list {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  header .utility__list {
    width: 100%;
    top: 60px;
    opacity: 0;
    z-index: 1;
    transition: none .5s ease 0s;
    visibility: hidden;
    position: absolute;
  }
}

header .utility__item {
  float: right;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  header .utility__item {
    float: none;
    text-align: center;
    width: 100%;
  }
}

header .utility__item:first-child {
  width: 50px;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  header .utility__item:first-child {
    width: 100%;
    line-height: 50px;
    height: 50px;
    display: block;
    margin-left: 0;
  }
  header .utility__item:first-child img {
    width: 80px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  header .utility__item:first-child a {
    padding: 9px 0 10px;
  }
}

header .utility__item a {
  line-height: 20px;
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  header .utility__item a {
    width: 100%;
    line-height: 50px;
    display: block;
    color: #07ace9;
  }
}

header .utility__item .fa {
  display: none;
}

header .is-active .utility__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  float: none;
  opacity: 1;
  visibility: visible;
  width: 100%;
}

header .is-active .utility__item + li {
  margin-left: 0;
}

header .is-active .utility__item + li a {
  line-height: 50px;
  border-top: solid 1px #07ace9;
  color: #07ace9;
}

.utility--products {
  float: right;
  margin: 10px 20px 0 0;
  width: calc(100% - 50%);
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .utility--products {
    margin: 15px 20px 0 0;
    width: calc(100% - 50%);
  }
}

@media screen and (max-width: 767px) {
  .utility--products {
    float: none;
    width: 100%;
    margin: 0px;
  }
}

.utility--products__list {
  margin: 0;
  padding: 0;
  float: right;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .utility--products__list {
    width: 100%;
    top: 60px;
    opacity: 0;
    z-index: 1;
    transition: none .5s ease 0s;
    visibility: hidden;
    position: absolute;
  }
}

.utility--products__item {
  width: auto;
  float: none;
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .utility--products__item {
    float: none;
    text-align: center;
    width: 100%;
  }
}

.utility--products__item:first-child a {
  width: auto;
  line-height: 20px;
  margin-right: 20px;
  padding: 3px 5px 3px 5px;
  color: #444444;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .utility--products__item:first-child a {
    display: block;
    background-position: 20px center;
    line-height: 44px;
    padding: 3px 5px 3px 20px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .utility--products__item:nth-of-type(2) {
    width: 100%;
    line-height: 50px;
    height: 50px;
    display: block;
    margin-left: 0;
    border: none;
    border-top: solid 1px #ccc;
  }
  .utility--products__item:nth-of-type(2) img {
    width: 80px;
    margin: 0 auto;
  }
}

.utility--products__item:nth-of-type(2) a {
  background-image: url(/images/common/icon/account_b.png);
  background-size: 10px;
  background-position: 5px center;
  line-height: 20px;
  margin-right: 20px;
  padding: 3px 5px 3px 20px;
  color: #444444;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .utility--products__item:nth-of-type(2) a {
    display: block;
    background-position: 20px center;
    line-height: 44px;
    padding: 3px 5px 3px 40px;
    text-align: left;
  }
}

.utility--products__item:nth-of-type(3) {
  border: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .utility--products__item:nth-of-type(3) {
    border: none;
    border-top: solid 1px #ccc;
  }
}

.utility--products__item:nth-of-type(3) a {
  background-image: url(/images/common/icon/back.png);
  background-size: 10px;
  background-position: 5px center;
  line-height: 20px;
  padding: 2px 5px 2px 20px;
  border-radius: 3px;
  color: #aaa;
}

@media screen and (max-width: 767px) {
  .utility--products__item:nth-of-type(3) a {
    display: block;
    background-position: 20px center;
    line-height: 44px;
    padding: 2px 5px 2px 40px;
    text-align: left;
  }
}

.utility--products__item .fa {
  display: none;
}

@media screen and (max-width: 767px) {
  header .is-active .utility--products {
    float: none;
    width: 100%;
    margin: 0px;
  }
}

header .is-active .utility--products__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  float: none;
  opacity: 1;
  visibility: visible;
  width: 100%;
}

header .is-active .utility--products__item + li {
  margin-left: 0;
}

header .is-active .utility--products__item + li a {
  line-height: 50px;
}

@media screen and (max-width: 767px) {
  header .is-active .utility--products__item + li a {
    border: none;
    display: block;
    background-position: 20px center;
    padding: 2px 5px 2px 40px;
    text-align: left;
  }
}

/* =========================================================
Footer
========================================================= */
.footer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  background-color: #eee;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    width: 100%;
  }
}

.footer__contents {
  width: 1000px;
  height: 440px;
  margin: 0 auto;
  padding: 30px 0 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer__contents {
    width: 95%;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .footer__contents {
    width: calc(100% - 20px);
    height: auto;
  }
}

.footer__corporate-profile {
  float: left;
  text-align: left;
  line-height: 2;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .footer__corporate-profile {
    float: none;
  }
}

.footer__corporate-profile a {
  color: #fff;
}

.footer .contentsList .first {
  width: 740px;
  text-align: left;
  float: left;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .first {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer .contentsList .first {
    width: 100%;
  }
}

.footer .contentsList .first__item {
  width: 200px;
  float: right;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .first__item {
    width: 30%;
    float: left;
  }
  .footer .contentsList .first__item:nth-of-type(n+3) {
    margin-left: 5%;
  }
}

@media screen and (max-width: 767px) {
  .footer .contentsList .first__item {
    width: 100%;
    float: none;
  }
}

.footer .contentsList .first__item:first-child {
  width: 500px;
  min-height: 300px;
  float: left;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .first__item:first-child {
    width: 100%;
    min-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .footer .contentsList .first__item:first-child {
    width: 100%;
    min-height: auto;
  }
}

.footer .contentsList .first__item:first-child .second__item {
  margin: 0 0 40px;
  line-height: 1;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .first__item:first-child .second__item {
    flex-basis: 32%;
  }
}

@media screen and (max-width: 767px) {
  .footer .contentsList .first__item:first-child .second__item {
    margin: 0;
  }
  .footer .contentsList .first__item:first-child .second__item.active {
    margin: 0 0 20px;
  }
}

.footer .contentsList .first__item:first-child .second__item a {
  margin: .5em 0;
  padding: 0 0 0 15px;
}

.footer .contentsList .first__item:first-child .second__item + li {
  margin-top: 0;
}

.footer .contentsList .first__item:first-child .third {
  margin-top: 0;
}

.footer .contentsList .first__item:first-child .third a {
  margin: 1em 0;
}

.footer .contentsList .first__item:last-child .second__item {
  line-height: 1;
  position: relative;
}

@media screen and (max-width: 767px) {
  .footer .contentsList .first__item:last-child .second__item {
    margin: 0;
  }
  .footer .contentsList .first__item:last-child .second__item.active {
    margin: 0 0 20px;
  }
}

.footer .contentsList .first__item > a {
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  line-height: 1;
  margin-bottom: 10px;
}

.footer .contentsList .second {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: solid 2px #ccc;
  padding-top: 10px;
}

@media screen and (max-width: 767px) {
  .footer .contentsList .second {
    display: block;
  }
}

.footer .contentsList .second__item {
  flex-basis: 240px;
  margin: .5em 0;
  padding: 0 0 0 8px;
  line-height: 1;
}

.footer .contentsList .second__item > a {
  font-size: 1.4rem;
  display: inline-block;
  line-height: 1;
  position: relative;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .second__item > a {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .footer .contentsList .second__item > a {
    margin: .5em 0;
  }
}

.footer .contentsList .second__item + li {
  margin-top: 0;
}

.footer .contentsList .second__item i {
  display: none;
  position: absolute;
  top: .15em;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 1.6rem;
  overflow: hidden;
  border-radius: 10px;
  padding-left: 1px;
}

@media screen and (max-width: 767px) {
  .footer .contentsList .second__item i {
    display: block;
  }
}

.footer .contentsList .second__item.active i {
  transform: rotate(-45deg);
}

.footer .contentsList .third {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .footer .contentsList .third {
    display: none;
  }
}

.footer .contentsList .third__item {
  font-size: 1.2rem;
  line-height: 1;
  margin: .5em 0;
  padding: 0 0 0 15px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .contentsList .third__item {
    font-size: 1.1rem;
  }
}

.footer .contentsList .third a {
  position: relative;
  padding: 0 0 0 15px;
  background: url(/images/common/arrow.png) center left no-repeat;
  background-size: 5px;
}

.footer .col {
  width: 220px;
  float: right;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .footer .col {
    float: none;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .footer .col {
    float: none;
    margin-bottom: 40px;
  }
}

footer .utility {
  margin: 10px 0 0;
}

footer .utility__list {
  margin: 0;
  padding: 0;
  text-align: left;
}

footer .utility__item {
  margin: 15px 0 0;
  padding: 0;
  line-height: 1;
  font-size: 1.4rem;
}

footer .utility__item .fa {
  display: none;
}

/* =========================================================
BacktoTop
========================================================= */
.backtoTop {
  margin: 0;
  padding: 10px 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: #07ace9;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 997;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .backtoTop {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .backtoTop {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    bottom: 0;
  }
}

.backtoTop i {
  display: inline-block;
  color: #fff;
  font-size: 5rem;
  margin: -7px 0 0 4px;
}

@media screen and (max-width: 767px) {
  .backtoTop i {
    font-size: 3.2rem;
    margin: -2px 0 0 3px;
  }
}

/* =========================================================
copyright
========================================================= */
.copyright {
  margin: 0;
  padding: 20px 50px;
  border-top: solid 2px #ccc;
}

.copyright p {
  margin: 0 auto;
  font-size: 1.4rem;
  clear: both;
}

/* =========================================================
Global Navigation
========================================================= */
@keyframes slide {
  0% {
    transform: translate(0, -100%);
    transform-origin: top center;
  }
  100% {
    transform: translate(0, 0);
    transform-origin: top center;
  }
}

@keyframes close {
  0% {
    transform: translate(0, 0);
    transform-origin: top center;
  }
  100% {
    transform: translate(0, -100%);
    transform-origin: top center;
  }
}

nav.menu {
  position: relative;
}

@media screen and (max-width: 767px) {
  nav.menu {
    z-index: -1;
  }
}

.gnavi {
  position: relative;
  float: right;
  margin-right: 20px;
  margin-top: 15px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .gnavi {
    float: none;
    margin: 10px auto 0;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .gnavi {
    background: #e3f5ff;
    background-size: cover;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    opacity: 0;
    transition: all .5s ease 0s;
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .is-active .gnavi {
    display: block;
    float: none;
  }
}

@media screen and (max-width: 767px) {
  .is-active .gnavi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    float: none;
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .gnavi.is-passive {
    display: block;
    float: none;
  }
}

@media screen and (max-width: 767px) {
  .gnavi.is-passive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    float: none;
    display: none;
    transition: none .5s ease 0s;
  }
}

.gnavi__list {
  margin: 0;
  padding: 0;
  position: relative;
  float: left;
  z-index: 100;
}

@media screen and (max-width: 767px) {
  .gnavi__list {
    float: none;
    width: 100%;
    margin: 0px;
    border-top: solid 1px #07ace9;
    border-bottom: solid 1px #07ace9;
    z-index: -1;
    margin-top: 160px;
  }
}

.gnavi__item {
  font-size: 1.8rem;
  display: block;
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
}

@media screen and (max-width: 767px) {
  .gnavi__item {
    height: auto;
    float: none;
    margin: 0;
    text-align: left;
  }
}

.gnavi__item + .gnavi__item {
  margin-left: calc(28px + 1em);
}

@media screen and (max-width: 767px) {
  .gnavi__item + .gnavi__item {
    margin-left: 0;
    border-top: solid 1px #07ace9;
  }
}

.gnavi__item + .gnavi__item:before {
  content: "/";
  position: absolute;
  left: calc(-14px - .65em);
}

@media screen and (max-width: 767px) {
  .gnavi__item + .gnavi__item:before {
    display: none;
  }
}

.gnavi a {
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  .gnavi a {
    color: #07ace9;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 0 0 20px;
  }
}

.gnavi__link {
  display: inline-block;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .gnavi__link {
    color: #fff;
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .gnavi__link {
    color: #fff;
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .gnavi__link:hover {
    color: #07ace9;
  }
}

.gnavi .fa-chevron-right {
  display: none;
}

@media screen and (max-width: 767px) {
  .gnavi .fa-chevron-right {
    display: inline-block;
    position: absolute;
    top: calc(50% - .5em);
    right: 2%;
  }
}

@media screen and (max-width: 767px) {
  .gnavi.gnavi--products .gnavi__list {
    margin-top: 210px;
  }
}

@media screen and (min-width: 1000px) {
  .gnavi.gnavi--products .gnavi__item + li {
    margin-left: calc(22px + 1em);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .gnavi.gnavi--products .gnavi__item + li {
    margin-left: calc(28px + 1em);
  }
}

@media screen and (min-width: 1000px) {
  .gnavi.gnavi--products .gnavi__item + li:before {
    left: calc(-11px - .65em);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .gnavi.gnavi--products .gnavi__item + li:before {
    left: calc(-14px - .65em);
  }
}

/* =========================================================
#button
========================================================= */
.button {
  background: transparent;
  display: inline-block;
  font-size: 1.4rem;
}

.button--round {
  padding: 12px 30px;
  line-height: 1;
  border-radius: 50vh;
}

@media screen and (max-width: 767px) {
  .button--round {
    display: block;
    text-align: center;
  }
}

.button--square {
  line-height: 1;
  padding: 8px 50px;
}

.button--fillWhite {
  background-color: #fff;
  color: #07ace9;
}

@media screen and (min-width: 1000px) {
  .button--fillWhite:hover {
    color: #fff;
    background-color: #07ace9;
  }
}

.button--lineWhite {
  border: solid 1px #fff;
  color: #fff;
}

@media screen and (min-width: 1000px) {
  .button--lineWhite:hover {
    background-color: #fff;
    color: #07ace9;
  }
}

.button--fillBlue {
  background-color: #07ace9;
  color: #fff;
}

@media screen and (min-width: 1000px) {
  .button--fillBlue:hover {
    background-color: #0687b7;
  }
}

.button--lineBlue {
  border: solid 1px #07ace9;
  color: #07ace9;
}

@media screen and (min-width: 1000px) {
  .button--lineBlue:hover {
    background-color: #07ace9;
    color: #fff;
  }
}

.label, .label--informaition, .label--news {
  background: transparent;
  color: #fff;
  font-size: 1rem;
  padding: 3px 10px;
  line-height: 1;
  border-radius: 2px;
  display: inline-block;
}

.label--informaition {
  background: #307dfb;
}

.label--news {
  background: #eb371a;
}

.qanda {
  padding: 40px 0;
  margin: 0 0 160px;
  background-color: #e3f5ff;
}

.qanda__item {
  background-color: #fff;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto 20px;
  transition: box-shadow .5s ease 0s;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .qanda__item {
    width: 95%;
    margin: 0 auto 10px;
  }
}

@media screen and (max-width: 767px) {
  .qanda__item {
    width: 95%;
    margin: 0 auto 10px;
  }
}

.qanda__item:hover {
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow .5s ease 0s;
}

.qanda__question {
  padding: 0 80px;
  position: relative;
  min-height: 60px;
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .qanda__question {
    padding: 0 60px 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .qanda__question {
    padding: 0 30px 0 50px;
    position: relative;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
  }
}

.qanda__question span {
  background-color: #07ace9;
  color: #fff;
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 3.6rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .qanda__question span {
    font-size: 2rem;
    width: 40px;
    height: 100%;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.qanda__question h3 {
  width: 840px;
  min-height: 60px;
  height: 60px;
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #07ace9;
}

.qanda__question h3 .block {
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .qanda__question h3 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .qanda__question h3 {
    width: 100%;
    min-height: 40px;
    font-size: 1.6rem;
    padding: 5px 0;
    height: auto;
  }
}

.qanda__question .switch {
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(/images/sanitation/arrow_down.png) center no-repeat;
  background-size: 16px;
  transition: transform .5s ease 0s;
}

@media screen and (max-width: 767px) {
  .qanda__question .switch {
    width: 30px;
    height: 100%;
    background-size: 10px;
  }
}

.qanda__question.active .switch {
  transform: rotate(180deg);
}

.qanda__answer {
  padding: 10px 20px 10px 80px;
  position: relative;
  min-height: 70px;
  display: none;
  transition: display .5s ease 0s;
}

@media screen and (max-width: 767px) {
  .qanda__answer {
    padding: 10px 10px 10px 50px;
  }
}

.qanda__answer span {
  background-color: #0039ca;
  color: #fff;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
}

@media screen and (max-width: 767px) {
  .qanda__answer span {
    font-size: 2rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.qanda__answer .answerBody {
  width: 900px;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  color: #0039ca;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .qanda__answer .answerBody {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .qanda__answer .answerBody {
    width: 100%;
    min-height: 30px;
    font-size: 1.6rem;
  }
}

.qanda__answer .block {
  color: #0039ca;
  font-size: 1.8rem;
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .qanda__answer .block {
    font-size: 1.6rem;
  }
}

.qanda__answer .block--img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: auto;
  height: auto;
}

@media screen and (max-width: 767px) {
  .qanda__answer .block--img {
    flex-direction: column;
  }
  .qanda__answer .block--img img + img {
    margin-top: 10px;
  }
}

.qanda__answer a {
  color: #0039ca;
  font-size: 1.8rem;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .qanda__answer a {
    font-size: 1.6rem;
  }
}

.qanda__answer .principle {
  font-size: 1.6rem;
}

@media screen and (min-width: 1000px) {
  .qanda__answer img {
    width: auto;
  }
}

/* =========================================================
#pagenation
========================================================= */
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 80px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .pagination {
    font-size: 1rem;
  }
}

table + .pagination {
  margin-top: -40px;
}

.pagination li.page-item + li {
  margin-left: 10px;
}

.pagination li.page-item .page-link {
  min-width: 30px;
  display: inline-block;
  border: solid 1px #999;
  padding: 6px 10px;
  text-align: center;
  color: #999;
}

@media screen and (max-width: 767px) {
  .pagination li.page-item .page-link {
    padding: 3px 5px;
    min-width: 20px;
  }
}

.pagination li.page-item .page-link:hover {
  border: solid 1px #07ace9;
  color: #07ace9;
}

.pagination li.page-item.active .page-link {
  border: solid 1px #07ace9;
  background-color: #07ace9;
  color: #fff;
}

/* =========================================================
#icon
========================================================= */
.icon {
  padding: .5em 1.25em;
  display: inline-block;
  line-height: 1;
  border-radius: 1em;
}

.icon--required {
  background: #e61f18;
  color: #fff;
  font-size: 1rem;
}

/* =========================================================
#modal
========================================================= */
.modal {
  display: none;
}

/* =========================================================
#color
========================================================= */
.accentcolor--primary {
  color: #ff8300;
}

.accentcolor--secondary {
  color: #422500;
}

.accentcolor--secondary-bold {
  color: #422500;
  font-weight: bold;
}

.alert-color {
  color: #cc0000;
}

/* =========================================================
#error
========================================================= */
.err {
  text-align: left;
  margin-top: 0;
  color: #e61f18;
}

.arrow-label > * {
  padding: 0 10px;
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
  background-color: #DFDED4;
  box-sizing: border-box;
}

.arrow-label :first-child {
  font-size: 14px;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .arrow-label :first-child {
    font-size: 12px;
  }
}

.arrow-label :first-child::after {
  content: "";
  position: absolute;
  margin-top: 1em;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  right: -0.5em;
}

.arrow-label :last-child {
  padding-left: 15px;
  width: 190px;
  font-size: 15px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .arrow-label :last-child {
    width: 180px;
    font-size: 13px;
  }
}

.arrow-label .orange {
  background-color: #FF832C;
}

.arrow-label .orange:after {
  border-left-color: #FF832C;
}

.arrow-label .red {
  background-color: #FF2D16;
}

.arrow-label .red:after {
  border-left-color: #FF2D16;
}

.arrow-label .green {
  background-color: #019565;
}

.arrow-label .green:after {
  border-left-color: #019565;
}

.arrow-label .blue {
  background-color: #479FD6;
}

.arrow-label .blue:after {
  border-left-color: #479FD6;
}

@keyframes show {
  from {
    transform: translate(0, 20px);
    transition: transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    transition: transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 1;
  }
}

@keyframes hide {
  from {
    transform: translate(0, 0);
    transition: transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 1;
  }
  to {
    transform: translate(0, 20px);
    transition: transform .3s ease 0s,opacity .3s ease 0s;
    opacity: 0;
  }
}

.disable {
  display: none;
}

@media screen and (min-width: 1000px) {
  .fadein {
    opacity: 0;
    transform: translate(0, 50px);
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .fadein {
    opacity: 0;
    transform: translate(0, 50px);
  }
}

@media screen and (min-width: 1000px) {
  .fadein.scrollin {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform .6s ease 0s,opacity .6s ease 0s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .fadein.scrollin {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform .6s ease 0s,opacity .6s ease 0s;
  }
}

.fadein.three-icon i {
  overflow: hidden;
  height: 30px;
}

@media screen and (min-width: 1000px) {
  .fadein.three-icon i span:nth-of-type(1) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .fadein.three-icon i span:nth-of-type(1) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .fadein.three-icon i span:nth-of-type(2) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .fadein.three-icon i span:nth-of-type(2) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .fadein.three-icon i span:nth-of-type(3) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .fadein.three-icon i span:nth-of-type(3) {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .scrollin.three-icon i span:nth-of-type(1) {
    margin-top: 0;
    transition: margin-top .3s ease .3s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .scrollin.three-icon i span:nth-of-type(1) {
    margin-top: 0;
    transition: margin-top .3s ease .3s;
  }
}

@media screen and (min-width: 1000px) {
  .scrollin.three-icon i span:nth-of-type(2) {
    margin-top: 0;
    transition: margin-top .3s ease .6s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .scrollin.three-icon i span:nth-of-type(2) {
    margin-top: 0;
    transition: margin-top .3s ease .6s;
  }
}

@media screen and (min-width: 1000px) {
  .scrollin.three-icon i span:nth-of-type(3) {
    margin-top: 0;
    transition: margin-top .3s ease .9s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .scrollin.three-icon i span:nth-of-type(3) {
    margin-top: 0;
    transition: margin-top .3s ease .9s;
  }
}

@media screen and (min-width: 1000px) {
  .type2 {
    transition-delay: 200ms;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .type2 {
    transition-delay: 200ms;
  }
}

@media screen and (min-width: 1000px) {
  .type3 {
    transition-delay: 400ms;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .type3 {
    transition-delay: 400ms;
  }
}

@media screen and (min-width: 1000px) {
  .slow {
    transition-delay: 200ms;
    transition: all 1s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .slow {
    transition-delay: 200ms;
    transition: all 1s;
  }
}

@media screen and (min-width: 1000px) {
  .slowx {
    transition-delay: 200ms;
    transition: all 1.5s;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .slowx {
    transition-delay: 200ms;
    transition: all 1.5s;
  }
}

/* =========================================================
Helper
========================================================= */
.format {
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.noborder {
  border: none !important;
}

.fsize8 {
  font-size: 0.8rem;
}

.fsize9 {
  font-size: 0.9rem;
}

.fsize10 {
  font-size: 1rem;
}

.fsize11 {
  font-size: 1.1rem;
}

.fsize12 {
  font-size: 1.2rem;
}

.fsize13 {
  font-size: 1.3rem;
}

.fsize14 {
  font-size: 1.4rem;
}

.fsize15 {
  font-size: 1.5rem;
}

.fsize16 {
  font-size: 1.6rem;
}

.fsize17 {
  font-size: 1.7rem;
}

.fsize18 {
  font-size: 1.8rem;
}

.fsize19 {
  font-size: 1.9rem;
}

.fsize20 {
  font-size: 2rem;
}

.fsize21 {
  font-size: 2.1rem;
}

.fsize22 {
  font-size: 2.2rem;
}

.fsize23 {
  font-size: 2.3rem;
}

.fsize24 {
  font-size: 2.4rem;
}

.fsize25 {
  font-size: 2.5rem;
}

.fsize26 {
  font-size: 2.6rem;
}

.fsize27 {
  font-size: 2.7rem;
}

.fsize28 {
  font-size: 2.8rem;
}

.fsize29 {
  font-size: 2.9rem;
}

.fsize30 {
  font-size: 3rem;
}

.fsize31 {
  font-size: 3.1rem;
}

.fsize32 {
  font-size: 3.2rem;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.fwbold {
  font-weight: bold;
}

.fwnormal {
  font-weight: normal;
}

.ffonospace {
  font-family: monospace;
}

.fsnormal {
  font-style: normal;
}

.fsitalic {
  font-style: italic;
}

.fsoblique {
  font-style: oblique;
}

.lheight10 {
  line-height: 1;
}

.lheight12 {
  line-height: 1.2;
}

.lheight13 {
  line-height: 1.3;
}

.lheight14 {
  line-height: 1.4;
}

.lheight15 {
  line-height: 1.5;
}

.lheight16 {
  line-height: 1.6;
}

.lheight17 {
  line-height: 1.7;
}

.lheight18 {
  line-height: 1.8;
}

.lheight20 {
  line-height: 2;
}

.lheight25 {
  line-height: 2.5;
}

.lheight30 {
  line-height: 3;
}

.tleft,
.tleft th,
.tleft td {
  text-align: left !important;
}

.tright,
.tright th,
.tright td {
  text-align: right !important;
}

.tcenter,
.tcenter th,
.tcenter td {
  text-align: center !important;
}

.bsolid1e {
  border: solid 1px #eee;
}

.bsolid2e {
  border: solid 2px #eee;
}

.bsolid3e {
  border: solid 3px #eee;
}

.bsolid1d {
  border: solid 1px #ddd;
}

.bsolid2d {
  border: solid 2px #ddd;
}

.bsolid3d {
  border: solid 3px #ddd;
}

.bsolid1c {
  border: solid 1px #ccc;
}

.bsolid2c {
  border: solid 2px #ccc;
}

.bsolid3c {
  border: solid 3px #ccc;
}

.bdashed1e {
  border: dashed 1px #eee;
}

.bdashed2e {
  border: dashed 2px #eee;
}

.bdashed3e {
  border: dashed 3px #eee;
}

.bdashed1d {
  border: dashed 1px #ddd;
}

.bdashed2d {
  border: dashed 2px #ddd;
}

.bdashed3d {
  border: dashed 3px #ddd;
}

.bdashed1c {
  border: dashed 1px #ccc;
}

.bdashed2c {
  border: dashed 2px #ccc;
}

.bdashed3c {
  border: dashed 3px #ccc;
}

.vtop,
.vtop img,
.vtop th,
.vtop td {
  vertical-align: top !important;
}

.vmiddle,
.vmiddle img,
.vmiddle th,
.vmiddle td {
  vertical-align: middle !important;
}

.vbottom,
.vbottom img,
.vbottom th,
.vbottom td {
  vertical-align: bottom !important;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.cboth {
  clear: both;
}

.kerning {
  letter-spacing: -.5px;
}

.cf {
  zoom: 1;
}

.cf:before, .cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.container:before,
.container:after,
.box:before,
.box:after,
article:before,
article:after,
header:before,
header:after,
footer:before,
footer:after {
  content: "";
  display: table;
}

.container:after,
.box:after,
article:after,
header:after,
footer:after {
  clear: both;
}

.container,
.box,
article,
header,
footer {
  zoom: 1;
}

.textLimit {
  list-style-position: inside;
}

.textLimit__item {
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.highlighter--pnk {
  background: linear-gradient(transparent 30%, #fbd 30%);
}

.highlighter--ylw {
  background: linear-gradient(transparent 30%, #ffb 30%);
}

.highlighter--ylw {
  background: linear-gradient(transparent 70%, #ff0 30%);
  display: inline;
}

.hanging {
  padding-left: 1em !important;
  display: inline-block;
  text-indent: -1em;
}
